import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { isIOS } from "../lib/is-ios";
import { navigate } from "gatsby";

export const DeviceOrientationRefContext = createContext(null)
export const DeviceOrientationContext = createContext(null)

export const useDeviceOrientation = () => useContext(DeviceOrientationContext)
export const useDeviceOrientationRef = () => useContext(DeviceOrientationRefContext)

const samples = 100
const range = 15

const computeMedian = (numbers) => {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

export const DeviceOrientationContextProvider = ({ children }) => {
  const deviceOrientationRef = useRef()
  const lastDeviceOrientationEvents = useRef([])
  const compassIsStableRef = useRef(false)
  const [deviceOrientation, setDeviceOrientation] = useState(null)
  useEffect(() => {

    const setDeviceOrientationContexts = (e) => {
      if (deviceOrientationRef.current === null) {
        // Runs onlu the first time we receive a DeviceOrientation Event
        if (deviceOrientation?.alpha === null || deviceOrientation?.beta === null || deviceOrientation?.gamma === null) {
          // Galaxy A02S sends DeviceOrientationEvents with alpha, beta and gamma set to null
          // At least, no gyroscopes and/or accelerometers
          navigate('/not-supported')
        }
        if (deviceOrientation?.webkitCompassHeading === undefined && deviceOrientation?.absolute === false) {
          // We are on Android, no 'real' north is available
          navigate('/not-supported')
        }
      }
      deviceOrientationRef.current = e
      setDeviceOrientation(e)
    }

    if (!isIOS()) {
      window.addEventListener("deviceorientationabsolute", setDeviceOrientationContexts)
    } else {
      window.addEventListener('deviceorientation', setDeviceOrientationContexts)
    }

    return () => {
      if (!isIOS()) {
        window.removeEventListener("deviceorientationabsolute", setDeviceOrientationContexts)
      } else {
        window.removeEventListener('deviceorientation', setDeviceOrientationContexts)
      }
    }
  }, [])

  if (!window.DeviceOrientationEvent || !('ontouchstart' in window)) {
    // https://stackoverflow.com/questions/18892719
    navigate('/not-supported')
  }

  if (deviceOrientation && !compassIsStableRef.current) {
    const lastCompassValue = deviceOrientation.absolute ? deviceOrientation.alpha : deviceOrientation.webkitCompassHeading
    const newBuffer = [...lastDeviceOrientationEvents.current]
    const bufferSize = newBuffer.push(lastCompassValue)
    if (bufferSize < samples) {
      console.log("collected", bufferSize, "samples over", samples)
      lastDeviceOrientationEvents.current = newBuffer
      // Wait for buffer to fill
    }
    else {
      //const median = computeMedian(newBuffer)
      const max = newBuffer.reduce((acc, curr) => {
        if (curr > acc) return curr
        return acc
      }, newBuffer[0])
      const min = newBuffer.reduce((acc, curr) => {
        if (curr < acc) return curr
        return acc
      }, newBuffer[0])
      lastDeviceOrientationEvents.current = newBuffer.slice(-samples)
      console.log({ min, max, newBuffer })
      const abs_diff = Math.abs(max - min)
      // The distance between 350 and 10 degrees is the same as the distance between 10 and 30 degrees === 20
      const distance = abs_diff > 180 ? 360 - abs_diff : abs_diff
      if (distance < range) {
        console.log('compass is now stable', distance, '<', range)
        compassIsStableRef.current = true
      }
      else {
        console.log('compass still not stable')
      }
    }
  }

  const deviceOrientationRefProviderValue = (!deviceOrientation || !compassIsStableRef.current) ? { current: undefined } : deviceOrientationRef
  return (
    <DeviceOrientationRefContext.Provider value={deviceOrientationRefProviderValue}>
      <DeviceOrientationContext.Provider value={deviceOrientation}>{children}</DeviceOrientationContext.Provider>
    </DeviceOrientationRefContext.Provider>
  )
}