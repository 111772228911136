import { graphql } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout"
import Viewer360 from "../components/viewer-360"
import { Container, Heading, Loader, Section } from "react-bulma-components"
import { DeviceOrientationContextProvider } from "../contexts/device-orientation-context"

const BackroundLoader = () => {

  return (
    <Section style={{
      position: "absolute",
      zIndex: -1
    }}>
      <Container>
        <Loader />
        <Heading size={4}>Chargement et calibration de la boussole...</Heading>
        <p><b>Tenez votre téléphone droit devant vous sans bouger</b></p>
        <br />
        <p>Si la vue met trop de temps à arriver, vérifiez que vous avez du réseau et cliquez sur la boussole pour relancer.</p>
      </Container>
    </Section>
  )
}

const Page = ({ pageContext: { config } }) => {
  const { slug } = config
  const { t } = useTranslation([slug])
  const tContent = t("content")
  const isBrowser = typeof window !== "undefined"

  if (!isBrowser) return <BackroundLoader />

  return (
    <Layout>
      <div>{tContent ? tContent.title : "No i18n"}</div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <DeviceOrientationContextProvider>
          {isBrowser && <Viewer360 config={config} />}
        </DeviceOrientationContextProvider>
      </div>
      <BackroundLoader />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
