import React, {
  Suspense,
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from "react"
import * as THREE from "three"
import { Canvas, useFrame, useLoader } from "@react-three/fiber"
import { TextureLoader } from "three"
import { useModal } from "../hooks/use-modal"
import { degToRad, radToDeg } from "three/src/math/MathUtils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleDot, faCompass } from "@fortawesome/free-solid-svg-icons"
import { Element, Icon, Loader, Section } from "react-bulma-components"
import { DeviceOrientationControls } from "@react-three/drei"
import { isIOS } from "../lib/is-ios"
import { useDeviceOrientation, useDeviceOrientationRef } from "../contexts/device-orientation-context"
import { useLocation } from '@reach/router'

const WORLD_RADIUS = 500
const DOT_RADIUS = 10
const CAMERA_DEVIATION_DEG = 90
const CAMERA_DEVIATION_RAD = degToRad(CAMERA_DEVIATION_DEG)


const roundIfIsNumber = value => {
  if (isNaN(value)) {
    return value + ""
  } else {
    return Math.round(value * 100) / 100
  }
}

const getNorth = (webkitCompassHeading, alpha, beta, gamma) => {
  if (webkitCompassHeading) {
    // iOS ! 
    // webkitCompassHeading is the deviation from alpha
    return webkitCompassHeading
  }
  else {
    // Android / DeviceOrientationAbsolute
    // alpha/beta/gamma is absolute and points to the north
    //let compass = -(alpha + beta * gamma / 90);
    //compass -= Math.floor(compass / 360) * 360; // Wrap to range [0,360]
    //return compass
    let north = 360 - alpha
    return north
  }
}

const getXFromWidthPercentage = w => {
  return (
    (WORLD_RADIUS / 2) *
    Math.cos(2 * Math.PI * (w - CAMERA_DEVIATION_DEG / 360))
  )
}
const getYFromHeightPercentage = h => {
  return (0.5 - h) * WORLD_RADIUS + DOT_RADIUS
}
const getZFromWidthPercentage = w => {
  return (
    (WORLD_RADIUS / 2) *
    Math.sin(2 * Math.PI * (w - CAMERA_DEVIATION_DEG / 360))
  )
}

const Dome = ({
  config,
  modal,
  setImageInfoContent,
  deviceOrientationRef,
  deviceOrientationControlsRef,
  debugEnabled
}) => {
  const { slug, dots: dotsInPercentages } = config
  const [colorMap] = useLoader(TextureLoader, [`/scenes/${slug}.jpg`])
  const dots = (dotsInPercentages ?? []).map(({ w, h, ...rest }) => ({
    ...rest,
    x: getXFromWidthPercentage(w),
    y: getYFromHeightPercentage(h),
    z: getZFromWidthPercentage(w),
  }))
  const domeRef = useRef()
  const onClickDot = useCallback(
    index => {
      setImageInfoContent({ ...dotsInPercentages[index] })
      modal.setIsDisplayed(true)
    },
    [modal, dotsInPercentages, setImageInfoContent]
  )

  useFrame(async ({ clock }) => {
    if (clock.running && deviceOrientationControlsRef.current && deviceOrientationControlsRef.current.deviceOrientation) {
      console.log("Clock running")
      const deviceOrientation = deviceOrientationRef.current
      const { webkitCompassHeading, alpha, beta, gamma } = deviceOrientation
      const north = getNorth(webkitCompassHeading, alpha, beta, gamma)
      console.log('north is', north)
      const relativeDeviceOrientation = deviceOrientationControlsRef.current.deviceOrientation
      console.log('relative orientation is', relativeDeviceOrientation)
      console.log('alpha compensation : ', relativeDeviceOrientation.alpha)
      const totalRotation = (north + relativeDeviceOrientation.alpha) % 360
      const totalRotationRad = degToRad(totalRotation)
      console.log('total rotation will be ', totalRotation, 'degrees')
      console.log('total rotation will be ', totalRotationRad, 'radians')
      domeRef.current.rotation.y = totalRotationRad
      clock.stop()
      console.log("Clock stopped")
    }
  })

  return (
    <group ref={domeRef}>
      <mesh scale={[-1, 1, 1]}>
        <hemisphereLight groundColor={'black'} />
        <sphereBufferGeometry
          attach="geometry"
          args={[
            WORLD_RADIUS, // radius — sphere radius. Default is 1.
            60, // widthSegments — number of horizontal segments. Minimum value is 3, and the default is 8.
            40, // heightSegments — number of vertical segments. Minimum value is 2, and the default is 6.
            -CAMERA_DEVIATION_RAD, // phiStart — specify horizontal starting angle. Default is 0. We shift the starting point by 90 degrees to begin to draw in front of the camera.
            (debugEnabled ? 0.999 : 1) * Math.PI * 2, // phiLength — specify horizontal sweep angle size. Default is Math.PI * 2.
          ]}
        />
        <Suspense fallback={<></>}>
          <meshBasicMaterial
            map={colorMap}
            attach="material"
            side={THREE.BackSide}
          />
        </Suspense>
      </mesh>
      {Array.isArray(dots) &&
        dots.map((dot, index) => (
          <mesh
            onClick={() => onClickDot(index)}
            key={index}
            position={[dot.x, dot.y, dot.z]}
          >
            <sphereBufferGeometry
              attach="geometry"
              args={[DOT_RADIUS, 10, 10]}
            ></sphereBufferGeometry>
            <meshPhongMaterial />
          </mesh>
        ))}
    </group>
  )
}

const SCENES = {
  INTRO: 0,
  GET_DEVICE_ORIENTATION: 1,
  VIEW_3D: 2,
}

const Viewer360 = ({ config }) => {
  const location = useLocation()
  const debugEnabled = location.search === '?debug'
  const [preloaded, setPreloaded] = useState(false)
  useEffect(() => {
    const preload = async () => {
      fetch(`/scenes/${config.slug}.jpg`).then(() => setPreloaded(true))
    }
    preload()
  }, [])
  const deviceOrientationControlsRef = useRef()
  const deviceOrientationRef = useDeviceOrientationRef()
  const compassDialog = useModal({ isDisplayed: false })
  const [useDebug, setUseDebug] = useState(debugEnabled)
  const imageInfo = useModal({ isDisplayed: false })
  const [imageInfoContent, setImageInfoContent] = useState({
    title: "Titre",
    body: "body",
  })
  if (!preloaded || !deviceOrientationRef.current) return <Section><Loader /></Section>
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: "10px",
          left: "10px",
          zIndex: 1,
        }}
      >
        <button
          className="button"
          onClick={() => {
            compassDialog.setIsDisplayed(true)
          }}
        >
          <Icon size="large" className="is-size-4">
            <FontAwesomeIcon icon={faCompass} />
          </Icon>
        </button>
      </div>
      <Canvas
        pixelRatio={window.devicePixelRatio}
        camera={{ near: 1, far: 1100, fov: 60, position: [0, 0, 0] }}
        controls={false}
      >
        <DeviceOrientationControls ref={deviceOrientationControlsRef} />
        <Suspense fallback={<></>}>
          <Dome
            config={config}
            deviceOrientationRef={deviceOrientationRef}
            deviceOrientationControlsRef={deviceOrientationControlsRef}
            modal={imageInfo}
            setImageInfoContent={setImageInfoContent}
            debugEnabled={debugEnabled}
          />
        </Suspense>
      </Canvas>
      {useDebug && <DebugOverlay />}
      
      <compassDialog.Modal
        title={"Recharger la vue"}
        body={
          <Element>
            <ul>
              <CompassHelper />
              <Element mb={3} className="content">
                Rien ne s'affiche ?<br />
                Vous pensez que la vue est mal orientée ?
                <ol>
                  <li>
                    Tenez votre smartphone droit devant vous  sans bouger.
                  </li>
                  <li>
                    Appuyez sur "Recharger la vue"
                  </li>
                </ol>
              </Element>
            </ul>
          </Element>
        }
        buttonLabel={"Recharger la vue"}
        action={() => window.location.reload()}
      />
      <imageInfo.Modal
        {...imageInfoContent} slug={config.slug}
      />
    </>
  )
}

export default Viewer360

const DebugOverlay = () => {
  const deviceOrientation = useDeviceOrientation()
  const debugRelativeDeviceOrientationRef = useRef(null)

  useEffect(
    () => {
      const setRef = (e) => debugRelativeDeviceOrientationRef.current = e
      window.addEventListener('deviceorientation', setRef)
      return () => window.removeEventListener('deviceorientation', setRef)
    }
  )


  return (
    <div
      style={{
        position: "absolute",
        top: "3.25rem",
        left: 0,
        zIndex: 10,
        background: "rgba(255, 255, 255, 0.5)",
      }}
    >
      {/* debug relative ref*/}
      <h1><b>COMPASS / NORTH / ABSOLUTE</b></h1>
      <table>
        <tbody>
          <tr>
            <td>isIOS</td>
            <td>{isIOS() ? 'iOS' : 'Android'}</td>
          </tr>
          <tr>
            <td>alpha</td>
            <td>{roundIfIsNumber(deviceOrientation.alpha)}</td>
          </tr>
          <tr>
            <td>beta</td>
            <td>{roundIfIsNumber(deviceOrientation.beta)}</td>
          </tr>
          <tr>
            <td>gamma</td>
            <td>{roundIfIsNumber(deviceOrientation.gamma)}</td>
          </tr>
          <tr>
            <td>absolute</td>
            <td>{deviceOrientation.absolute + ""}</td>
          </tr>
          <tr>
            <td>webkitCompassHeading&nbsp;</td>
            <td>{roundIfIsNumber(deviceOrientation.webkitCompassHeading)}</td>
          </tr>
          <tr>
            <td>North</td>
            <td>{roundIfIsNumber(getNorth(deviceOrientation.webkitCompassHeading, deviceOrientation.alpha, deviceOrientation.beta, deviceOrientation.gamma))}</td>
          </tr>
        </tbody>
      </table>
      {/* debug relative ref*/}
      <h1><b>RELATIVE</b></h1>
      {debugRelativeDeviceOrientationRef.current && <table>
        <tbody>
          <tr>
            <td>isIOS</td>
            <td>{isIOS() ? 'iOS' : 'Android'}</td>
          </tr>
          <tr>
            <td>alpha</td>
            <td>{roundIfIsNumber(debugRelativeDeviceOrientationRef.current.alpha)}</td>
          </tr>
          <tr>
            <td>beta</td>
            <td>{roundIfIsNumber(debugRelativeDeviceOrientationRef.current.beta)}</td>
          </tr>
          <tr>
            <td>gamma</td>
            <td>{roundIfIsNumber(debugRelativeDeviceOrientationRef.current.gamma)}</td>
          </tr>
          <tr>
            <td>absolute</td>
            <td>{debugRelativeDeviceOrientationRef.current.absolute + ""}</td>
          </tr>
          <tr>
            <td>webkitCompassHeading&nbsp;</td>
            <td>{roundIfIsNumber(debugRelativeDeviceOrientationRef.current.webkitCompassHeading)}</td>
          </tr>
        </tbody>
      </table>
      }
    </div>
  )
}

const orientations = ["au Nord", "au Nord-Est", " à l'Est", "au Sud-Est", " au Sud", "au Sud-Ouest", "à l'Ouest", "au Nord-Ouest", "au Nord"]

export const CompassHelper = () => {

  const deviceOrientation = useDeviceOrientation()
  const { webkitCompassHeading, alpha, beta, gamma } = deviceOrientation
  const north = getNorth(webkitCompassHeading, alpha, beta, gamma)
  let orientation = Math.trunc((north + 45 / 2) / 45)
  return (
    <Element mb={3}>
      Votre appareil est orienté {orientations[orientation]}<br />
    </Element>
  )
}